import React from 'react';

import { useCycleAd, useSetupAd } from '@client/core/hooks';
import {
  BannerAdObj,
  isBannerAd,
  isNativeAd,
  isTakeover,
  isVideoAd,
  RecommendationCategories,
  LayoutType,
  NativeAdObj,
  PlacementId,
  VideoAdObj
} from '@schibsted-nmp/advertising-shared';
import { Placeholder } from '@client/core/components/placeholder/Placeholder';
import { getAdConfig } from '@client/core/state/reducer';
import useLayoutTypeSub from '@client/core/hooks/useLayoutTypeSub';
import {
  useGetPlacementClassName,
  useSetAdvtComponentStyle
} from '@client/core/hooks/useSetAdvtComponentStyle';

import BannerAdComponent from '../banner/BannerAdComponent';
import NativeAdComponent from '../native/NativeAdComponent';
import VideoAdComponent from '../video/VideoAdComponent';
import TakeoverAdComponent from '../takeover/TakeoverAdComponent';
import { WallpaperAdComponent } from '../wallpaper/WallpaperAdComponent';

export type AdSwitchProps = {
  containerId: string;
  placementId: PlacementId;
  initialLayoutType?: LayoutType;
  categories?: RecommendationCategories;
};

const { deviceType } = getAdConfig();
export function AdSwitchComponent(props: AdSwitchProps) {
  const { placementId, containerId, initialLayoutType, categories } = props;
  const targetId = useCycleAd(placementId);
  const layoutType = useLayoutTypeSub(initialLayoutType || 'grid');

  const { adObj, status } = useSetupAd({
    placementId,
    targetId,
    containerId,
    categories
  });

  const placementClassName = useGetPlacementClassName({ placementId });
  useSetAdvtComponentStyle(placementId, containerId, targetId, status, adObj);

  if (!adObj) return null;
  if (isTakeover(adObj, deviceType)) {
    return (
      <TakeoverAdComponent
        placementId={placementId}
        targetId={targetId}
        adObj={adObj}
      />
    );
  }
  if (placementId === 'wallpaper') {
    return (
      <WallpaperAdComponent placementId={placementId} targetId={targetId} />
    );
  }
  if (isNativeAd(adObj)) {
    return (
      <NativeAdComponent
        adObj={adObj as NativeAdObj}
        targetId={targetId}
        placementId={placementId}
        status={status}
        layoutType={layoutType}
      />
    );
  }
  if (isBannerAd(adObj)) {
    return (
      <BannerAdComponent
        adObj={adObj as BannerAdObj}
        targetId={targetId}
        placementId={placementId}
        className={placementClassName}
      />
    );
  }
  if (isVideoAd(adObj)) {
    return (
      <VideoAdComponent
        adObj={adObj as VideoAdObj}
        targetId={targetId}
        placementId={placementId}
      />
    );
  }
  return <Placeholder targetId={targetId} />;
}
