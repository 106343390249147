import React from 'react';

export function Label({
  className,
  classes,
  adLabel
}: {
  classes: {
    badge: string;
    neutral: string;
  };
  adLabel: string;
  className?: string;
}) {
  return (
    <span className={`${classes.badge} ${classes.neutral} ${className}`}>
      {adLabel}
    </span>
  );
}

export function Customlabel({ adLabel }) {
  return (
    <span
      className="absolute top-10 left-10 pointer-events-none p-4 text-12"
      style={{
        borderBottomRightRadius: '4px',
        background: '#f0f0f2'
      }}
    >
      {adLabel}
    </span>
  );
}
