import {
  ConsentStatus,
  PermissionValue
} from '@schibsted-nmp/advertising-shared';

export function subscribeToConsent(
  callback: (permissionValue: PermissionValue) => void
) {
  window?._tcf_?.isConsentedToAll((consentValue) => {
    callback(consentValue ? '1' : '0');
  });
}

export function getConsent(): boolean | null {
  return window?._tcf_?.getConsentedToAllSync();
}

export function getPermissionValueIfExists(): ConsentStatus {
  const consentValueSync = getConsent();
  return consentValueSync ? ConsentStatus.accepted : ConsentStatus.rejected;
}

export function getConsentOrSubscribe(
  onConsentReady: (permissionValue: PermissionValue) => void
) {
  const consentValueSync = getConsent();
  const consentNotSetYet = consentValueSync === null;

  if (consentNotSetYet) {
    subscribeToConsent((permissionValue) => {
      onConsentReady(permissionValue);
    });
  } else {
    onConsentReady(consentValueSync ? '1' : '0');
  }
}
