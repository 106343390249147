import React from 'react';

import { Customlabel } from '@client/xandr/components/native/parts/Label';
import { i18n } from '@lingui/core';
import NativeLogo from '@client/xandr/components/native/parts/NativeLogo';
import { Props } from '@client/xandr/components/native/NativeAdComponent';
import {
  escapeString,
  getDescription
} from '@client/xandr/components/native/native.helper';

/**
 * Native ads on front and object pages are always recommendation ads.
 *
 * The reason this is not being retrieved from search-components is that there
 * are no search components matching this. Recommendations are creating their own components.
 *
 * We could improve this by checking on placementIDs instead of pageType,
 * if we create a util isRecommendationAd(placementId) but then it's string matching.
 */
export const RecommendationNativeAd = ({ adObj }: Props) => {
  const { native } = adObj;
  const adLabel = i18n.t({
    id: 'native.advertisement',
    message: 'advertisement'
  });

  return (
    <article className="relative isolate hover:s-bg-primary-subtle focus:s-bg-primary-subtle p-8 rounded-8 ">
      <div
        className="absolute"
        aria-owns="search-ad-native-ad-stream__native-ad-1"
      ></div>
      <div className="mb-2">
        <div className="aspect-1/1">
          <img
            alt={native.title}
            className="w-full h-full object-center object-cover rounded-8 border s-border"
            src={native.image.url}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <span className="text-s s-text-subtle">
          {escapeString(native.sponsoredBy ? native.sponsoredBy : '')}
        </span>
        <div className="flex gap-2">
          <h2
            className="h4 mb-0 break-words"
            id="search-ad-native-ad-stream__native-ad-1"
          >
            <a
              className="sf-search-ad-link s-text! hover:no-underline"
              href={native.clickUrl}
              id={native.title}
            >
              <span className="absolute inset-0" aria-hidden="true"></span>
              {native.title}
            </a>
          </h2>
          <div>
            <NativeLogo constrainWidth={true} native={native} />
          </div>
        </div>

        <div className="">
          <span>{getDescription(native)}</span>
        </div>
      </div>
      <Customlabel adLabel={adLabel} />

      <div className="absolute inset-0 pointer-events-none outline-none rounded-8 border-2 border-transparent sf-search-ad-outline"></div>
    </article>
  );
};
