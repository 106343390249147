import React from 'react';

import {
  LayoutType,
  NativeAdObj,
  Vertical,
  PlacementId,
  AdStatus
} from '@schibsted-nmp/advertising-shared';
import { getAdConfig } from '@client/core/state/reducer';
import { useLoadNativeAd } from '@client/core/hooks';

import NativeCogwheel from './parts/NativeCogwheel';
import { NativeAdSwitch } from './NativeAdSwitch';

export type Props = {
  adObj: NativeAdObj;
  targetId: string;
  placementId: PlacementId;
  className?: string;
  status: AdStatus;
  layoutType?: LayoutType;
  pageType?: string;
  vertical?: string;
};

function shouldUseExtraCogwheelInset(
  pageType: string,
  vertical: Vertical
): boolean {
  const extraCogwheelInset: Vertical[] = ['car', 'mobility'];
  const objectRecommerce = pageType === 'object';
  return objectRecommerce || extraCogwheelInset?.includes(vertical);
}

export default function NativeAdComponent(props: Props) {
  const { targetId, adObj, status, layoutType } = props;
  const { vertical, pageType } = getAdConfig();

  useLoadNativeAd(adObj, targetId, status);

  return (
    <div id={targetId}>
      <NativeAdSwitch {...props} vertical={vertical} pageType={pageType} />
      <NativeCogwheel
        extraInset={
          shouldUseExtraCogwheelInset(pageType, vertical) &&
          layoutType !== 'list'
        }
        adObj={adObj}
      />
    </div>
  );
}
