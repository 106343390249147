import { actions, dispatch, getState } from '@client/core/state/reducer';
import {
  AfsConfig,
  Keywords,
  SearchConfigBody,
  SearchKey,
  SearchParams,
  debugLog,
  AdConfig,
  AfsPlacement
} from '@schibsted-nmp/advertising-shared';
import fetcher from '@client/core/utils/api/fetcher';
import { getPermissionValueIfExists } from '@client/core/utils/consent';

import { setPageOpts } from '../../xandr/xandr';
import {
  $config,
  incrementCycleToRefreshBanners,
  setGamTargetingAtom
} from '../atoms/config';
import { LegacyConfig } from '../../../server/types/apiResponseTypes';
import { $afsAtom } from '../atoms/afs';

export type FilterUpdatePayloadType = {
  searchKey: SearchKey;
  searchParams: SearchParams;
};

type SearchConfigResponse = {
  data: {
    keywords: Keywords;
    afs?: AfsConfig;
  };
};

function shouldUpdateKeywords(payload: FilterUpdatePayloadType): boolean {
  // If the search params have changed, we need to update the keywords.
  // Here we can do more specific checks such as update on pagination but do not on sort.

  if (Object.entries(getState().searchParams.new).length === 0) return false;
  if (!payload.searchKey) return false;

  return getState().searchParams.current !== getState().searchParams?.new;
}

export const searchFilterCallback = async (event) => {
  try {
    const payload = event.payload as FilterUpdatePayloadType;
    debugLog(`Received search filter update:`, payload);

    dispatch(actions.setSearchParams({ new: payload.searchParams }));
    if (shouldUpdateKeywords(payload)) {
      const { deviceType, vertical, subvertical } = $config.get();
      const consent = getPermissionValueIfExists();
      const body = {
        searchKey: payload.searchKey,
        searchParams: payload.searchParams,
        deviceType,
        consent,
        vertical,
        subvertical
      } as SearchConfigBody;

      const [searchFilterConfig, legacySearchFilterConfig] = await Promise.all([
        await fetcher<{
          data: AdConfig;
          error: string;
        }>('/api/update/targeting', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }),
        await fetcher<SearchConfigResponse>('/api/legacy/search-config', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
      ]);

      const filterData = searchFilterConfig?.data as AdConfig;
      if (filterData?.adServer?.gam) {
        setGamTargetingAtom(filterData.adServer.gam.targeting);
      }

      if (filterData?.adServer?.afs) {
        debugLog(`Sending AFS config to AFS ad vendor`, {
          afs: filterData?.adServer?.afs
        });
        $afsAtom.set({
          config: filterData?.adServer?.afs as AfsConfig,
          placements: filterData?.placements as AfsPlacement[]
        });
      }

      const legacyFilterData = legacySearchFilterConfig?.data as LegacyConfig;
      if (legacyFilterData.keywords) {
        dispatch(actions.updateKeywords(legacyFilterData.keywords));

        if (legacyFilterData?.keywords) {
          setPageOpts({ keywords: legacyFilterData?.keywords }, () => {
            incrementCycleToRefreshBanners();
          });
        }

        dispatch(actions.setSearchParams({ current: payload.searchParams }));
      }
    }
  } catch (error) {
    console.error(`Error in search-config API fetch: ${error}`);
  }
};
